*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background-image: url('https://i.stack.imgur.com/9TQsy.png');
}

html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.app {
    margin: 50px 0;
}

.fab {
    margin: 0px !important;
    top: auto !important;
    right: 20px !important;
    bottom: 20px !important;
    left: auto !important;
    position: fixed !important;
}